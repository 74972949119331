import React, { useEffect, useState } from 'react';
import Web3 from 'web3';

import { round } from 'mathjs';

import smartContractConf from "src/build/contracts/Vesting.json";

import tokenAbi from 'src/_data/tokentAbi.json'


import {
  Container,
  Grid,
  Box,
  Typography
} from '@mui/material';


import AddVesting from './AddVesting';
import Release from './Release';
import LastVested from './LastVested';


const IndexView = ({
  web3,
  accounts,
  connected
}) => {
  const [smartContract, setSmartContract] = useState(null);
  const [address, setAddress] = useState(null);
  const [tokenInstance, setTokenInstance] = useState(null);

  const [totalVested, setTotalVested] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [hasFinancialRole, setHasFinancialRole] = useState(null);

  const [viewAccount, setViewAccount] = useState(null);
  const [transactionCost, setTransactionCost] = useState(0);

  useEffect(() => {
    initSmartContracts();
  }, [web3, accounts]);


  useEffect(() => {
    if (smartContract) {
      init();
    }
  }, [smartContract, tokenInstance]);

  const init = async () => {
    estimateFees();
    refreshBalance();
    checkAccess();
  };

  
  const initSmartContracts = async () => {
    if (connected !== true || (accounts !== null && accounts.length === 0) || !web3) {
      return false;
    }

    const abi = smartContractConf.abi;
    const addressLocal = smartContractConf.networks[process.env.REACT_APP_PROVIDER_NETWORK].address;
    setAddress(addressLocal);

    const betSwap = new web3.eth.Contract(abi, addressLocal);
    setSmartContract(betSwap);

    const tokenI = new web3.eth.Contract(tokenAbi, process.env.REACT_APP_TOKEN_ADDRESS);
    setTokenInstance(tokenI);
  };
  

  const refreshBalance = async () => {
    if (smartContract === null || tokenInstance === null) {
      return false;
    }

    let tVested = null;
    try {
      tVested = await smartContract.methods.totalVested().call();
    } catch (error) {
      console.log(error);
    }
    
    setTotalVested(tVested);

    let tBalance = null;
    try {
      tBalance = await tokenInstance.methods.balanceOf(address).call();
    } catch (error) {
      console.log(error);
    }
    
    setTotalBalance(tBalance);
  }

  const estimateFees = async () => {
    if (smartContract === null) {
      return false;
    }
    const time = parseInt((Date.now()) / 1000);
    let gasPrice = 0;
    let gas = 0;
    let cost = 0;

    try {
      gasPrice = await web3.eth.getGasPrice();
      gas = await smartContract.methods.addVesting(
        accounts[0],
        '100000000000000000000',
        time,
        (time + 100000)
      ).estimateGas({from: accounts[0]});

      cost = (gas * gasPrice) / 1000000000000000000;
    } catch (error) {
      console.log(error);
    }

    cost = round(cost, 4);
    
    setTransactionCost(cost);
  };

  const checkAccess = async () => {
    if (connected === true && accounts !== null && smartContract !== null && accounts.length > 0 && web3) {
      let hasRole = null;
      try {
        hasRole = await smartContract.methods.hasRole(web3.utils.soliditySha3('FINANCIAL_ROLE'), accounts[0]).call();
      } catch (error) {
        console.log(error);
      }
      
      setHasFinancialRole(hasRole);
    }
  }

  const viewVestingAccount = (acc) => {
    setViewAccount(acc);
  };

  return (
    <>
    {connected === true && accounts !== null && <Container maxWidth={false}>

      {accounts.length > 0 && <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Connected Account: {accounts[0]}
        </Typography>
      </Box>}

      {smartContractConf !== null && accounts.length > 0 && <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Smart Contract: {smartContractConf.networks[process.env.REACT_APP_PROVIDER_NETWORK].address}
        </Typography>
      </Box>}

      {smartContractConf !== null && totalBalance !== 0 && <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Total Vested Balance: {Web3.utils.fromWei(totalVested.toString())}
        </Typography>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Unassigned Balance: {Web3.utils.fromWei(totalBalance) - Web3.utils.fromWei(totalVested)}
        </Typography>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Approximate Transaction Cost: {transactionCost} ETH
        </Typography>
      </Box>}

      {hasFinancialRole === true && <Grid
        container
        spacing={3}
      >
        <Grid item lg={6} md={6} sm={6}>
          {web3 !== null && address !== null && <>
          <AddVesting
          web3={web3}
          accounts={accounts}
          smartContract={smartContract}
          address={address}
          refreshBalance={refreshBalance}
          />
          <Box my={2}>
            <LastVested
            smartContract={smartContract}
            viewVestingAccount={viewVestingAccount}
            refreshLastVested={totalVested}
            />
           </Box>
          </>}
        </Grid>
          
        <Grid item lg={6} md={6} sm={6}>
          {web3 !== null && address !== null && <Release
          web3={web3}
          accounts={accounts}
          smartContract={smartContract}
          refreshBalance={refreshBalance}
          viewAccount={viewAccount}
          />}
        </Grid>
      </Grid>}

      {hasFinancialRole === false && <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          Your account must have permissions to operate with Vesting.
        </Typography>
      </Box>}

    </Container>}
    </>
  );
};

export default IndexView;
